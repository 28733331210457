<template>
  <div class="shop_wrap" >
    <div class="tabs_wrap" >
      <div class="tabs_item"
           :class="{ 'tabs_active': current == 0 }"
           @click="tabChange(0)"
      >
        去过的门店
      </div>

      <div class="tabs_item"
           :class="{ 'tabs_active': current == 1 }"
           @click="tabChange(1)"
      >
        附近门店
      </div>
    </div>

    <div class="shop_list" @scroll="handleScroll" >
      <div class="shop_item" v-for="(item, index) in list" :key="index" >
        <div class="shop_left" >
          <img :src="item.outsideImgUrl" class="shop_img" />
          <div class="shop_info" >
            <div class="shop_name" >{{ item.name }}</div>
            <div class="shop_phone" >{{ item.tel }}</div>
            <div class="shop_address" >{{ item.address }}</div>
          </div>
        </div>
        <div class="line" ></div>
        <div class="distance_wrap" >
          <div>距离{{ item.distance }}</div>
          <div class="img_wrap" >
            <img src="./img/phone.png" class="img_icon" @click="callPhone(item.tel)" />
            <img src="./img/location.png" class="img_icon" @click="toNav(item)" />
          </div>
        </div>
      </div>

      <van-empty
        v-if="list.length <= 0 && !loading"
        :image="empty"
        description="暂无门店"
      />
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getNearShopListByPage,
  getCustomerShopPage
} from '@/api/shop'
import {
  getSign
} from "@/api/device";
import wx from 'weixin-js-sdk'
import empty from '../report/img/6.png'
export default {
  name: 'shop',
  data() {
    return {
      current: 0,
      page: 1,
      pageSize: 10,
      list: [],
      lat: '',
      lng: '',
      loading: true,
      empty
    }
  },
  methods: {
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50){
        this.page ++
        switch (this.current) {
          case 0:
            this.getCustomerShopPage()
            break
          case 1:
            this.getNearShopListByPage()
            break
        }
      }
    },
    toNav(item) {
      let lat = parseFloat(item.latitude)
      let lng = parseFloat(item.longitude)
      let address = item.address
      let url = `http://uri.amap.com/marker?position=${lng},${lat}&name=${address}&src=mypage&coordinate=gaode&callnative=00`
      window.location.href = url
    },
    getSign() {
      const that = this
      getSign({ url: window.location.href}).then(res => {
        if(res.success) {
          let params = res.data
          wx.config({
            debug: false, // 生产环境需要关闭debug模式
            appId: params.appId, // appId通过微信服务号后台查看
            timestamp: params.timestamp, // 生成签名的时间戳
            nonceStr: params.nonceStr, // 生成签名的随机字符串
            signature: params.signature, // 签名
            jsApiList: [ // 需要调用的JS接口列表
              'getLocation'
            ]
          })
          wx.ready(()=> {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var lat = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var lng = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                that.lat = lat
                that.lng = lng
                // 获取附近的店铺
                // 安卓
                // let url = `androidamap://viewMap?sourceApplication=appname&poiname=${name}&lat=${lat}&lon=${long}&dev=0`
                // ios
                // let url = `http://uri.amap.com/marker?position=${longitude},${latitude}&name=${'汇智金融企业总部B座'}&src=mypage&coordinate=gaode&callnative=00`
                // window.location.href = url
                that.getCustomerShopPage()

              },
              fail:function(err) {
                // Toast('位置获取失败')
                window.location.reload()
              }
            })
          })
        }
      })
    },
    callPhone(tel) {
      window.location.href = `tel:${tel}`
    },
    tabChange(current) {
      this.current = current
      this.list = []
      this.page = 1
      switch (current) {
        case 0:
          this.getCustomerShopPage()
          break
        case 1:
          this.getNearShopListByPage()
          break
      }
    },
    getCustomerShopPage() {
      const { page, pageSize, lat, lng } = this
      let values = {
        page, pageSize, latitude: lat, longitude: lng
      }
      this.loading = true
      getCustomerShopPage({ ...values }).then(res => {
        this.loading = false
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    },
    getNearShopListByPage() {
      const { page, pageSize, lat, lng } = this
      let values = {
        page, pageSize, latitude: lat, longitude: lng
      }
      this.loading = true
      getNearShopListByPage({ ...values }).then(res => {
        this.loading = false
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    }
  },
  mounted() {
    this.getSign()
  }
}
</script>
