import { axios } from "@/utils/request";

// 2020202 查询附近的店铺
export function getNearShopListByPage(data) {
  return axios({
    url: '/customerShop/getNearShopListByPage',
    method: 'post',
    data
  })
}

// 2020201 获取去过的店列表
export function getCustomerShopPage(data) {
  return axios({
    url: '/customerShop/getCustomerShopPage',
    method: 'post',
    data
  })
}
